<template>
  <v-row
      class="align-content-center"
  >
    <v-col
        cols="12"
        md="6"
        class="order-2 order-md-1"
    >
      <v-card
          :image="require('../assets/handwash-portrait.jpg')"
          class="mx-auto"
          height="400"
          elevation="4"
          max-width="70%"
      >
      </v-card>
    </v-col>
    <v-col
        cols="12"
        md="6"
        class="order-1 order-md-2"
    >
      <v-card
          variant="flat"
ΩΩ      >
        <v-card-title
            class="text-h4 text-center text-md-h2 text-decoration-underline text-green-darken-4"
        >
          Our Mission
        </v-card-title>
        <v-card-text
            class="text-body-1 font-weight-light"
        >
          Our mission is to build impactful partnerships to create sustainable, thriving communities across
          Ghana. We focus on clean water and sanitation, resilient agriculture, preventive healthcare, climate-adaptive
          practices, and inclusive socio-economic support, all contributing to the Sustainable Development Goals (SDGs)
          by 2030.
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MissionComponent"
}
</script>

<style scoped>

</style>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2 mb-5" }
const _hoisted_2 = { class: "mt-15 mb-5" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_slider = _resolveComponent("home-slider")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_mission_component = _resolveComponent("mission-component")!
  const _component_mandate_component = _resolveComponent("mandate-component")!
  const _component_membership_component = _resolveComponent("membership-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_home_slider)
    ]),
    _createVNode(_component_v_divider),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_mission_component)
    ]),
    _createVNode(_component_v_divider),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_mandate_component)
    ]),
    _createVNode(_component_v_divider),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_membership_component)
    ])
  ], 64))
}
<template>
  <v-card
      :image="require('../assets/gourd.jpeg')"
      height="25vh"
  >
    <v-card-title
        class="text-h1 text-center text-white ">Gallery</v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "GalleryBanner"
}
</script>

<style scoped>

</style>
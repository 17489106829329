<template>
  <borehole-component />
</template>

<script>
import BoreholeComponent from "@/components/BoreholeComponent";
export default {
  name: "BoreholeView",
  components: {BoreholeComponent},
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>
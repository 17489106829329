<template>
  <v-card
      :image="require('../assets/tapleft.jpg')"
  >
    <v-card-title class="text-h4 text-md-h2 text-center text-white">Institutional Partners</v-card-title>
  </v-card>
  <v-row justify="center" dense>
    <v-card
        v-for="(partner, i) in institutionalPartners"
        :title="partner.name"
        :subtitle="partner.abb"
        :key="i"
        width="300"
        class="ma-2 "
    >
      <v-tooltip
          activator="parent"
      >{{ partner.name }} ({{ partner.abb }})</v-tooltip>
      <template v-slot:actions>
        <v-btn :href="partner.website" target="_blank">Visit</v-btn>
      </template>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "InstitutionalPartners",
  data() {
    return {
      institutionalPartners: [
        {
          name: 'Ministry of Local Government and Rural Development',
          abb: 'MLGRD',
          website: ''
        },
        {
          name: 'Ministry of Sanitation and Water Resources',
          abb: 'MSWR',
          website: ''
        },
        {
          name: 'Ministry of Health',
          abb: 'MOH',
          website: ''
        },
        {
          name: 'Ghana Health Service',
          abb: 'GHS',
          website: ''
        },
        {
          name: 'Ministry of Tourism, Arts and Culture',
          abb: 'MOTAC',
          website: ''
        },
        {
          name: 'Ministry of Lands and Natural Resources',
          abb: 'MNLR',
          website: ''
        },
        {
          name: 'Forestry Commission',
          abb: 'FC',
          website: ''
        },
        {
          name: 'Ministry of Works and Housing',
          abb: 'MWH',
          website: ''
        },
        {
          name: 'Public Works Department',
          abb: 'PWD',
          website: ''
        },
        {
          name: 'Ministry of Food and Agriculture',
          abb: 'MFA',
          website: ''
        },
        {
          name: 'Community Water and Sanitation Agency',
          abb: 'CWSA',
          website: ''
        },{
          name: 'Department of Cooperatives',
          abb: '',
          website: ''
        },{
          name: 'Rural Enterprises Development',
          abb: 'RED',
          website: ''
        },{
          name: 'National Youth Employment Programme',
          abb: 'NYEP',
          website: ''
        },
        {
          name: 'National Youth Authority',
          abb: 'NYA',
          website: ''
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
<template>
<v-app-bar
    :elevation="5"
    density="prominent"
    scroll-behavior="hide"
>
  <template v-slot:prepend>
  </template>
  <v-app-bar-title >
    <a href="/">
      <img :src="require('../assets/logo.png')" alt="Company Logo" height="100"/>
    </a>
  </v-app-bar-title>
  <v-menu open-on-hover>
    <template v-slot:activator="{props}">
      <v-btn v-bind="props" class="my-auto hidden-xs">Projects</v-btn>
    </template>
    <v-list>
      <v-list-item
          v-for="(project,i) in projects"
          :key="i"
          link
          :to="project.link"
      >{{ project.title }}</v-list-item>
    </v-list>
  </v-menu>
  <v-btn to="/partners" class="my-auto hidden-xs">Partners</v-btn>
  <v-btn to="/gallery" class="my-auto hidden-xs">Gallery</v-btn>
  <v-btn to="/about" class="my-auto hidden-xs">About</v-btn>
  <v-dialog max-width="500">
    <template v-slot:activator="{props: activatorProps}">
      <v-btn
          color="primary"
          variant="flat"
          v-bind="activatorProps"
          class="my-auto mr-sm-2">Donate</v-btn>
    </template>
      <v-card
          class="text-center"
          title="Make Your Donation"
      >
        <v-img :src="require('../assets/logo.png')" height="200"/>
        <donation-form />
      </v-card>
  </v-dialog>
  <v-menu
  >
    <template v-slot:activator="{props}">
      <v-btn
          class="my-auto  hidden-sm-and-up"
          icon="mdi-dots-vertical"
          v-bind="props"></v-btn>
    </template>
    <v-list>
      <v-list-item to="/partners">
        Partners
      </v-list-item>
      <v-list-item to="/gallery">
        Gallery
      </v-list-item>
      <v-list-item to="/about">
        About
      </v-list-item>
      <v-menu>
        <template v-slot:activator="{props}">
          <v-list-item v-bind="props" class="my-auto">Projects</v-list-item>
        </template>
        <v-list>
          <v-list-item
              v-for="(project,i) in projects"
              :key="i"
              link
              :to="project.link"
          >{{ project.title }}</v-list-item>
        </v-list>
      </v-menu>


    </v-list>
  </v-menu>
</v-app-bar>
</template>

<script>
import DonationForm from "@/components/DonationForm.vue";

export default {
  name: "NavBar",
  data () {
    return {
      projects:[
        {
          title: 'Boreholes',
          link: '/projects/boreholes'
        },
        {
          title: 'Community Mechanized Water Systems',
          link: '/projects/community-mechanized-water-systems'
        }
      ]
    }
  },
  components: {
    DonationForm
  }
}
</script>

<style scoped>

</style>
<template>
<div>
    <h4
        class="text-h4 text-md-h2 text-center text-green-darken-4 text-decoration-underline py-5 mb10"
    >Memberships</h4>
    <v-list>
      <v-row >
        <v-col
            v-for="(membership, i) in memberships"
            :key="i"
            cols="12"
            sm="6"
        >
          <v-list-item class="text-center text-blue-darken-4">
            <v-icon icon="mdi-check"></v-icon>  {{membership.name}}
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>

</div>
</template>

<script>
export default {
  name: "MembershipComponent",
  data() {
    return {
      memberships: [
        {
          name: 'Coalition of NGOs in Water and Sanitation',
          abb: 'CONNIWAS'
        },
        {
          name: 'Ghana Civil Society Organizations',
          abb: 'GCSOs'
        },
        {
          name: 'Ghana Social Welfare'
        },
        {
          name: 'Upper East Civil Society Association'
        },
        {
          name: 'NGOs in Energy Development'
        },
        {
          name: 'NGOs in Health and Hygiene'
        },
        {
          name: 'NGOs in Rural Agriculture'
        },
        {
          name: 'NGOs in Gender, Child and Social Protection'
        },

      ]
    }
  }
}
</script>

<style scoped>

</style>
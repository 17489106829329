<template>

  <div class="mt-2 mb-5">
    <home-slider />
  </div>
  <v-divider></v-divider>
  <div class="mt-15 mb-5">
    <mission-component />
  </div>
  <v-divider></v-divider>
  <div class="mb-5">
    <mandate-component />
  </div>
  <v-divider></v-divider>
  <div class="mb-5">
    <membership-component />
  </div>

</template>


<script lang="ts">
import { defineComponent } from 'vue';
import HomeSlider from '../components/HomeSlider.vue'
import MissionComponent from "@/components/MissionComponent.vue";
import MandateComponent from "@/components/MandateComponent.vue";
import MembershipComponent from "@/components/MembershipComponent.vue";

// Components

export default defineComponent({
  name: 'HomeView',

  components: {
    MembershipComponent,
    MandateComponent,
    MissionComponent,
    HomeSlider
  },
});
</script>

<template>
  <v-card
      :image="require('../assets/tapright.jpg')"
  >
    <v-card-title class="text-h4 text-md-h2 text-white text-center m">Donor Partners</v-card-title>
  </v-card>
<v-row justify="center" dense>
  <v-card
      v-for="(partner, i) in donorParters"
      :title="partner.name"
      :subtitle="partner.country"
      :key="i"
      width="300"
      class="ma-2 "

  >
    <v-tooltip
        activator="parent"
    >{{ partner.name }} ({{ partner.abb }})</v-tooltip>
    <template v-slot:actions>
      <v-btn :href="partner.website" target="_blank">Visit</v-btn>
    </template>
  </v-card>
</v-row>
</template>

<script>
export default {
  name: "DonorPartners",
  data () {
    return {
      donorParters: [
        {
          name: 'Aqua For All',
          country: 'Netherlands',
          website: 'https://aquaforall.org/'
        },
        {
          name: 'Rotary Club',
          country: 'International',
          website: 'https://www.rotary.org/'
        },
        {
          name: 'CORDAID Netherlands',
          country: 'Netherlands',
          website: 'https://www.cordaid.org/'
        },
        {
          name: 'Floor Ghana',
          country: 'Ghana',
          // website: 'https://www.rotary.org/'
        },
        {
          name: 'Rabobank Foundation',
          country: 'International',
          website: 'https://www.rabobank.com/'
        },
        {
          name: 'Achmea Foundation',
          country: 'Netherlands',
          website: 'https://www.achmea.nl/foundation/'
        },
        {
          name: 'ASN Foundation',
          country: 'Netherlands',
          website: 'https://www.asn-foundation.nl/'
        },
        {
          name: 'Green Africa Youth Foundation (GAYO)',
          country: 'Denmark',
          website: 'https://greenafricayouth.com/'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <community-component />
  </div>
</template>

<script>
import CommunityComponent from "@/components/CommunityComponent";
export default {
  name: "CommunityView",
  components: {CommunityComponent}
}
</script>

<style scoped>

</style>
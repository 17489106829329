<template>
  <v-app>
    <nav-bar />
    <v-main>
      <router-view/>
    </v-main>
    <v-divider></v-divider>
    <app-footer />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavBar from "@/components/NavBar.vue";
import AppFooter from "@/components/AppFooter.vue";


export default defineComponent({
  name: 'App',
  components: {AppFooter, NavBar},
  data () {
    return {
      //
    }
  },
})
</script>

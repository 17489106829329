<template>
  <v-carousel
      show-arrows="hover"
      :height="carouselHeight"
      hide-delimiters
      cycle>
    <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :style="{transform: `translateY(${item.offset}px)`}"
        :src="item.image"
        :alt="item.altText"
    >

    </v-carousel-item>
  </v-carousel>

</template>

<script>
export default {
  name: "HomeSlider",
  data (){
    return {
      items: [
        {
          altText: 'Woman fetching water from kalabash-aid project',
          image: require('../assets/slideshow1.jpeg'),
          offset: 0
        },
        {
          altText:  'Founder and community leader',
          image: require('../assets/slideshow2.jpeg'),
          offset: 0
        },
        {
          altText:  'Founder at site',
          image: require('../assets/slideshow3.jpeg'),
          offset: 0
        },
        {
          altText:  'Founder and happy beneficiary',
          image: require('../assets/slideshow4.jpeg'),
          offset: 0
        },

      ]
    }
  },
  computed: {
    carouselHeight() {
      // Adjust the height based on window width
      return window.innerWidth < 768 ? '40vh' : '65vh'; // Smaller height for mobile
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <gallery-banner />
  <v-container fluid>
    <v-row dense>
      <v-col
          v-for="(item, i) in galleryItems"
          :key="i"
          :cols="item.flex.sm"
          :md="item.flex.md"
          :xl="item.flex.xl"
      >
        <v-card
            :image="item.image"
            :height="500"
        >
          <v-card-title></v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GalleryBanner from "@/components/GalleryBanner";
export default {
  name: "GalleryView",
  data() {
    return {
      galleryItems: [
        {
          image: require('../assets/borehole.png'),
          altText: '',
          flex: {
            sm:12,
            md:6,
            xl: 4,
          }
        },
        {
          image: require('../assets/borehole1.png'),
          altText: '',
          flex: {
            sm:12,
            md:6,
            xl: 4,
        }
        },
        {
          image: require('../assets/farewell.png'),
          altText: '',
          flex: {
            sm:12,
            md:6,
            xl: 4,
        }
        },
        {
          image: require('../assets/satisfiedcommunity.png'),
          altText: '',
          flex: {
            sm:12,
            md:6,
            xl: 4,
        }
        },
        {
          image: require('../assets/structure.png'),
          altText: '',
          flex: {
            sm:12,
            md:6,
            xl: 4,
        }
        },
        {
          image: require('../assets/woman.png'),
          altText: '',
          flex: {
            sm:12,
            md:6,
            xl: 4,
        }
        },
        {
          image: require('../assets/taps.png'),
          altText: '',
          flex: {
            sm:12,
            md:6,
            xl: 4,
        }
        }
      ]
    }
  },
  components: {GalleryBanner}
}
</script>

<style scoped>

</style>
<template>

 <v-container>
   <v-img
       :src="require('../assets/logo.png')"
       height="200"
   />
   <v-card-title
       class="text-h3 text-center"
   >Borehole Projects</v-card-title>
   <v-row justify="center" dense>
     <v-card
         v-for="(project, i) in boreholeProjects"
         :title="project.community"
         :subtitle="project.donor"
         :key="i"
         width="300"
         class="ma-2 "

     >
       <v-tooltip
           activator="parent"
       >{{ project.contact.email }}</v-tooltip>
       <template v-slot:actions>
         <v-btn :href="project.contact.email">Contact</v-btn>
       </template>
     </v-card>
   </v-row>
 </v-container>
</template>

<script>
export default {
  name: "BoreholeComponent",

  data () {
    return {
      boreholeProjects: [
        {
          community: 'Mirigu-Azinzoka',
          district: 'Kasena-Nankana West',
          donor: 'Green Cross Netherlands',
          contact: [
            {
              email: 'sietzeweejes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
        {
          community: 'Nayagenia-Yibanabia',
          district: 'Kasena-Nankana West',
          donor: 'Rotary Club of Zutphen',
          contact:
            {
              email: 'sietzeweejes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Balobia-Asanabisi',
          district: 'Kasena-Nankana West',
          donor: 'Rotary Club of Zutphen',
          contact:
            {
              email: 'sietzeweejes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Zuarungu-Nyakoko ',
          district: 'Bolgatanga',
          donor: 'Equal Opportunity Fund',
          contact:
            {
              email: 'anniemanders@home.nl',
              name: '',
              phoneNumber: ''
            }

        },
        {
          community: 'Tongo Gbeogo School',
          district: 'Talensi/Nabdam',
          donor: 'Equal Opportunity Fund',
          contact:
            {
              email: 'anniemanders@home.nl',
              name: '',
              phoneNumber: ''
            }

        },
        {
          community: 'Bundunia',
          district: 'Kasena-Nankana East ',
          donor: 'Calabash Holland/ Wiljo family',
          contact:
            {
              email: '',
              name: 'Mr. Jan Fleurkens (Elder brother of Wiljo)',
              phoneNumber: '0031651750468'
            }

        },
        {
          community: 'Kandiga-Akamo',
          district: 'Kasena-Nankana West',
          donor: 'Rotary Club Of Zutphen',
          contact:
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Nayagenia-Kogwania',
          district: 'Kasena-Nankana East',
          donor: 'Catholic organization for development aid (CORDAID',
          contact:
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Vunania-Ayogreyire',
          district: 'Kasena-Nankana East',
          donor: 'Rotary Club Of Zutphen',
          contact:
            {
              email: '',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Vunania-Akadaribisi ',
          district: 'Kasena-Nankana East',
          donor: 'Rotary Club Of Zutphen',
          contact:
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Akurugu Daboo-Bochum',
          district: 'Kasena-Nankana East',
          donor: 'Aqua for All',
          contact:
            {
              email: 'd.bouman@aquaforall.nl sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Nayagenia-Badania',
          district: 'Kasena-Nankana East',
          donor: 'Aqua for All',
          contact:
            {
              email: 'd.bouman@aquaforall.nl sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Janania',
          district: 'Kasena-Nankana East',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Doba-North',
          district: 'Kasena-Nankana WestDistrict',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Kandiga-Afrontoa',
          district: 'Kasena-Nankana East',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Kandiga-Afrontoa 2',
          district: 'Kasena-Nankana East',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Naveo-Kazugu',
          district: 'Kasena-Nankana West',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Azinzoka',
          district: 'Kasena-Nankana West District',
          donor: 'Aqua for All',
          contact:
            {
              email: 'd.bouman@aquaforall.nl sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Doba-South',
          district: 'Kasena-Nankanan Municipality',
          donor: 'Calabash Holland / Wiljo family',
          contact:
            {
              email: '',
              name: 'Mr. Jan Fleurkens ( Elder brother of Wiljo)',
              phoneNumber: '0031651750468'
            }

        },
        {
          community: 'Natugnia',
          district: 'Kasena-Nankana Municipality',
          donor: 'Calabash Holland / Wiljo family',
          contact:
            {
              email: '',
              name: 'Mr. Jan Fleurkens ( Elder brother of Wiljo)',
              phoneNumber: '0031651750468'
            }

        },
        {
          community: 'Nakongo',
          district: 'Kasena-Nankana West District',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Katiu ',
          district: 'Kasena-Nankana West District',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Yogbania',
          district: 'Kasena-Nankana Municipality',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: 'Maarten Mastenbroek',
              name: '',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Bornia-Kapuru',
          district: 'Kasena-Nankana Municipality',
          donor: 'Floor Ghana Netherland',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Wuru',
          district: 'Kasena-Nankana Municipality',
          donor: 'Sietze Weesjes Funds',
          contact:
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Pungu-Nimbasinia',
          district: 'Kasena-Nankana Municipality',
          donor: 'Sietze Weesjes Funds',
          contact:
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Navrongo-Precast area',
          district: 'Kasena-Nankana Municipality',
          donor: 'Sietze Weejes Funds',
          contact:
            {
              email: 'sietzeweesjes@hot',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        },
        {
          community: 'Kandiga-Kurugu',
          district: 'Kasena-Nankana West District',
          donor: 'Green Africa Youth Foundation',
          contact:
            {
              email: '',
              name: 'Desmond Gayo',
              phoneNumber: '004917659509180'
            }

        },
        {
          community: 'Kologo',
          district: 'Kasena-Nankana Municipality',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'Kologo',
          district: 'Kasena-Nankana Municipality',
          donor: 'Floor Ghana Netherlands',
          contact:
            {
              email: '',
              name: 'Maarten Mastenbroek',
              phoneNumber: '0031619618252'
            }

        },
        {
          community: 'St. Johns Integrated Special School (Tono)',
          district: 'Kasena-Nankana Municipality',
          donor: 'Sietze Weesjes Funds',
          contact:
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }

        }

      ]
    }
  }
}
</script>

<style scoped>

</style>
<template>
<v-row
>
  <v-col cols="12" md="6">
    <donor-partners />
  </v-col>
  <v-col cols="12" md="6">
    <institutional-partners />
  </v-col>

</v-row>
</template>

<script>
import DonorPartners from "@/components/DonorPartners";
import InstitutionalPartners from "@/components/InstitutionalPartners";
export default {
  name: "PartnersView",
  components: {InstitutionalPartners, DonorPartners}
}
</script>

<style scoped>

</style>
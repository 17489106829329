<template>
<!--  <v-btn-->
<!--      icon="mdi-close"-->
<!--      variant="text"-->
<!--      @click="isActive.value = false"-->
<!--  ></v-btn>-->
<v-form
    class="justify-center pa-10"
    ref="form"
>
  <v-label>Choose your currency: </v-label>
  <v-btn-toggle>
    <v-btn
        color="primary"
        v-model="currency"
        v-for="(cash,i) in money"
        :key="i"
        :value="cash.symbol">
      {{ cash.symbol }}
    </v-btn>
  </v-btn-toggle>
  <v-label>Choose Amount: </v-label>
  <v-btn-toggle v-model="donationAmount">
    <v-btn value="100">
      100
    </v-btn>
    <v-btn value="50">
      50
    </v-btn>
    <v-btn value="20">
      20
    </v-btn>
    <v-btn value="10">
      10
    </v-btn>
  </v-btn-toggle>
  <v-container class="d-flex pt-3 ga-2">
    <v-text-field
        type="number"
        label="Amount"
        v-model="donationAmount"
        class="d-inline-flex"
        variant="outlined"
        density="compact"
    >
      <template v-slot:prepend>
        <v-label>Other Amount: {{ currency }}</v-label>
      </template>
    </v-text-field>
  </v-container>
  <v-btn color="success">Donate</v-btn>

</v-form>
</template>

<script>


export default {
  name: "DonationForm",

  data() {
    return {
      donationAmount: 100,
      currency: '',
      money: [
        {
          country: 'Ghana',
          currency: 'Ghana Cedi',
          symbol: 'GH₵'
        },
        {
          country: 'United States',
          currency: 'Dollar',
          symbol: '$'
        },
        {
          country: 'Europe',
          currency: 'Euro',
          symbol: '€'
        },
        {
          country: 'Great Britain',
          currency: 'Pound',
          symbol: '£'
        },
        {
          country: 'China',
          currency: 'Ren Min Bi',
          symbol: '¥'
        },
      ]


    }
  }
}
</script>

<style scoped>

</style>
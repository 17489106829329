import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import GalleryView from '../views/GalleryView.vue';
import CommunityView from "../views/CommunityView.vue";
import BoreholeView from "../views/BoreholeView.vue";
import PartnersView from "../views/PartnersView.vue";
import Vue from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/partners',
    name: 'partners',
    component: PartnersView
  },
  {
    path: '/projects/boreholes',
    name: 'boreholes',
    component: BoreholeView
  },
  {
    path: '/projects/community-mechanized-water-systems',
    name: 'community',
    component: CommunityView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router

<template>
  <v-container>
    <v-img
        :src="require('../assets/logo.png')"
        height="200"
    />
    <v-card-title
        class="text-h3 text-center"
    >Community Mecganized Water Systems</v-card-title>
    <v-row justify="center" dense>
      <v-card
          v-for="(project, i) in commMechWaterSupply"
          :title="project.community"
          :subtitle="project.donor"
          :key="i"
          width="300"
          class="ma-2 "

      >
        <v-tooltip
            activator="parent"
        >{{ project.contact.email }}</v-tooltip>
        <template v-slot:actions>
          <v-btn :href="project.contact.email">Contact</v-btn>
        </template>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CommunityComponent",
  data () {
    return {
      commMechWaterSupply : [
        {
          community: 'Kajelo',
          district: 'Kasena-Nankana West District',
          donor: 'Catholic Organization for Development Aid (CORDAID)',
          contact: [
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'rs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
        {
          community: 'Sokabisi',
          district: 'Bolgatanga Municipality',
          donor: 'Calabash Holland /Kiwanis Club',
          contact: [
            {
              email: '',
              name: 'Mr. Jan Fleurkens ( Elder brother of Wiljo)',
              phoneNumber: '0031651750468'
            }
          ]
        },
        {
          community: 'Mirigu-Azinzoka',
          district: 'Kasena-Nankana West District',
          donor: 'Green Cross Netherlands',
          contact: [
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
        {
          community: 'Akurugu-Daboo Abempengo',
          district: 'Kasena-Nankana Municipality',
          donor: 'Equal Opportunity Fund',
          contact: [
            {
              email: 'anniemanders@home.nl',
              name: '',
              phoneNumber: ''
            }
          ]
        },
        {
          community: 'Manyoro',
          district: 'Kasena-Nankana Municipality',
          donor: 'Sietze Weesjes Funds',
          contact: [
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
        {
          community: 'Kandiga-Bimbisi',
          district: 'Kasena-Nankana West District',
          donor: 'Aqua for All',
          contact: [
            {
              email: 'd.bouman@aquaforall.nl sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
        {
          community: 'Nogsenia (hospital area)',
          district: 'Kasena-Nankana Municipality',
          donor: 'Rotary club of Zutphen',
          contact: [
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
        {
          community: 'Zuarungu-Nyakoko',
          district: 'Bolgatanga East District',
          donor: 'Aqua for All',
          contact: [
            {
              email: 'd.bouman@aquaforall.nl sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
        {
          community: 'Gbeogo Deaf School',
          district: 'Talensi District',
          donor: 'Equal Opportunity Fund',
          contact: [
            {
              email: 'anniemanders@home.nl',
              name: '',
              phoneNumber: ''
            }
          ]
        },
        {
          community: 'Nayagenia',
          district: 'Kasena-Nankana Municipality',
          donor: 'Catholic Organization For Development Aid (CORDAID)',
          contact: [
            {
              email: 'sietzeweesjes@hotmail.com',
              name: 'Drs. Hans van de Sanden',
              phoneNumber: '0031653363716'
            }
          ]
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>